
import { Vue } from "vue-class-component";
import { Sounds, Packs } from "./sounds";

// let playlist = ["1.1", "2.5", "p2000", "2.10"];

interface Sound {
    part: number;
    index: number;
    file: string;
    name: string;
    path: string;
    source: string;
    error?: boolean;
    voice?: string;
}

// interface Pause {
//     delay: number;
// }

/*
@Options({
    components: {

    },
});
*/

export default class App extends Vue {
    /*
    data(): {
        sounds: Sound[];
        playlist: string[];
        playIndex: number;
        tempTimeout: number | null;
        searchTerm: string;
    } {
        return {
            sounds: [],
            playlist: [],
            playIndex: 0,
            tempTimeout: null,
            searchTerm: "",
        };
    }
    */

    sounds: Sound[] = [];
    packs = Packs;
    // playlist: Sound[] | Pause[] = [];
    playlist: string[] = [];
    playIndex = 0;
    tempTimeout: number | null = null;
    searchTerm = "";
    isPlaying = false;
    isPreviewing = false;
    pauseAmounts = [100, 250, 500, 1000, 2000, 3000];
    previewFile = "";
    packsEnabled: Record<string, boolean> = {};

    mounted() {
        this.loadSounds();

        if (location.hash) {
            const hashPlaylist = location.hash.substring(1).split(",");
            if (hashPlaylist && hashPlaylist.length > 0) {
                // for (let clip of hashPlaylist) {
                //
                // }
                // for (let item of hashPlaylist) {
                //     this.playlist.push(this.lookupAudio(item) ?? { delay: 500 });
                // }
                this.playlist = hashPlaylist;
            }
        }

        const player = this.$refs.player as HTMLAudioElement;
        player.addEventListener("ended", () => {
            this.handlePlaylist();
        });

        const preview = this.$refs.preview as HTMLAudioElement;
        preview.addEventListener("ended", () => {
            this.isPreviewing = false;
            this.previewFile = "";
        });
        preview.addEventListener("error", () => {
            console.error("error playing preview", preview.src, this.previewFile);
            this.sounds.filter((s) => s.path == this.previewFile)[0].error = true;
            // this.isPreviewing = false;
            // this.previewFile = "";
        });
    }

    loadSounds() {
        for (let part in Sounds) {
            for (let i in Sounds[part]) {
                let sound = Sounds[part][i];
                let name = sound.file.replace(".ogg", "");
                let path = sound.folder ? `./sounds/${sound.folder}/${sound.file}` : `./sounds/Del ${parseInt(part) + 1}/${sound.file}`;
                this.sounds.push({
                    part: parseInt(part) + 1,
                    index: parseInt(i) + 1,
                    file: sound.file,
                    name: name,
                    source: sound.source ?? "",
                    voice: sound.voice ?? "",
                    path: path,
                });
            }
        }
        for (let pack of this.packs) {
            this.packsEnabled[pack] = true;
            console.log("enable pack", pack);
        }
        console.log("sounds loaded", this.sounds);
    }
    async testSounds() {
        const preview = this.$refs.preview as HTMLAudioElement;
        for (let sound of this.sounds) {
            this.isPreviewing = true;
            this.previewFile = sound.path;
            preview.src = sound.path;
            preview.play();
            await new Promise((r) => setTimeout(r, 200));
            preview.pause();
            preview.currentTime = 0;
            this.isPreviewing = false;
        }
        this.isPreviewing = false;
    }
    lookupAudio(id: string) {
        const part = parseInt(id.split(".")[0]);
        const index = parseInt(id.split(".")[1]);
        const search = (this.sounds as Sound[]).filter((v) => v.part == part && v.index == index);
        return search.length > 0 ? search[0] : null;
    }
    previewAudio(part: number, index: number) {
        const file = this.lookupAudio(`${part}.${index}`);
        if (!file) return;
        const preview = this.$refs.preview as HTMLAudioElement;
        if (this.previewFile == file.path) {
            preview.pause();
            preview.currentTime = 0;
            this.previewFile = "";
            return;
        }
        preview.src = file.path;
        preview.play();
        this.previewFile = file.path;
        this.isPreviewing = true;
        console.log("preview audio", file);
    }
    addAudio(part: number, index: number) {
        this.playlist.push(`${part}.${index}`);
        this.previewAudio(part, index);
        this.updateHash();
    }
    removeAudio(index: number) {
        (this.playlist as string[]).splice(index, 1);
        this.updateHash();
    }
    moveAudio(index: number, direction: number) {
        if (index == 0 && direction == -1) return;
        if (index >= this.playlist.length && direction == 1) return;
        let el = this.playlist[index];
        this.playlist.splice(index, 1);
        this.playlist.splice(index + direction, 0, el);
        this.updateHash();
    }
    addPause(amount: number) {
        this.playlist.push(`p${amount}`);
        this.updateHash();
    }
    playPlaylist() {
        console.log("start playback");
        if (this.playlist.length == 0) {
            alert("Ingenting i spellistan!");
            return;
        }
        const player = this.$refs.player as HTMLAudioElement;

        let firstFile = this.lookupAudio(this.playlist[0]);
        if (!firstFile) return;
        player.src = firstFile.path;
        console.log("play file", firstFile);
        player.play();
        this.isPlaying = true;
    }
    stopPlaylist() {
        const player = this.$refs.player as HTMLAudioElement;
        this.playIndex = 0;
        player.pause();
        if (this.tempTimeout) clearTimeout(this.tempTimeout);
        this.isPlaying = false;
    }
    clearPlaylist() {
        if (!confirm("Vill du rensa spellistan?")) return;
        this.stopPlaylist();
        (this.playlist as string[]).splice(0, this.playlist.length);
        this.updateHash();
    }
    async copyLink() {
        await navigator.clipboard.writeText(window.location.toString());
        alert("Länk kopierad!");
    }
    updateHash() {
        // https://stackoverflow.com/a/2295951
        if (this.playlist.length == 0) {
            if (window.history && window.history.pushState) {
                window.history.pushState("", "", window.location.pathname);
            } else {
                window.location.href = window.location.href.replace(/#.*$/, "#");
            }
            return;
        }
        window.location.hash = (this.playlist as string[]).join(",");
    }
    handlePlaylist() {
        console.log("audio ended, handle next one");

        if (!this.isPlaying) {
            console.warn("not playing, sound ended anyway, abort");
            return;
        }

        const player = this.$refs.player as HTMLAudioElement;

        this.playIndex++;

        if (this.playIndex >= this.playlist.length) {
            console.log("stopped, base length exceeded");
            this.playIndex = 0;
            this.isPlaying = false;
            return false;
        }

        let playNext = this.playlist[this.playIndex];
        if (playNext.substring(0, 1) === "p") {
            let timeout = parseInt(playNext.substring(1));

            if (this.playIndex + 1 >= this.playlist.length) {
                console.log("stopped, base length exceeded");
                this.playIndex = 0;
                this.isPlaying = false;
                return false;
            }

            playNext = this.playlist[this.playIndex + 1];

            if (playNext.substring(0, 1) === "p") {
                alert("Två pauser i rad stöds ej (än)");
                console.error("two pauses in a row");
                this.playIndex = 0;
                this.isPlaying = false;
                return false;
            }

            let nextAudio = this.lookupAudio(playNext);
            if (nextAudio) {
                player.src = nextAudio.path;
                console.log("timeout", timeout);

                this.tempTimeout = setTimeout(() => {
                    console.log("timeout done, play file", nextAudio);
                    this.playIndex++;
                    player.play();
                }, timeout);
            } else {
                console.error("invalid audio");
            }
        } else {
            console.log("play", playNext);
            let nextAudio = this.lookupAudio(playNext);

            if (nextAudio) {
                player.src = nextAudio.path;
                console.log("play file", nextAudio);
                player.play();
            }
        }
    }

    get filteredSounds() {
        let filtered = this.sounds.filter((s) => {
            return this.packsEnabled[s.source != "" ? s.source : "classic"] && s.file.toLowerCase().includes(this.searchTerm.toLowerCase());
        });
        let ordered = filtered.sort((a, b) => {
            if (b.file < a.file) return 1;
            if (b.file > a.file) return -1;
            return 0;
        });
        return ordered;
        // if (!this.searchTerm) return this.sounds;
        // return this.sounds.filter((s) => s.file.indexOf(this.searchTerm) !== -1);
    }

    get isDev() {
        return process.env.node_env === "development";
    }

    get clientVersion() {
        return process.env.VUE_APP_VERSION; // injected
    }
}
