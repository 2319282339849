import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  ref: "player",
  src: ""
}
const _hoisted_2 = { ref: "preview" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "playlist" }
const _hoisted_6 = ["disabled", "onClick"]
const _hoisted_7 = ["disabled", "onClick"]
const _hoisted_8 = ["disabled", "onClick"]
const _hoisted_9 = {
  key: 0,
  class: "playlist-empty"
}
const _hoisted_10 = { class: "controls" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "search-field" }
const _hoisted_14 = { class: "search-packs" }
const _hoisted_15 = ["onUpdate:modelValue"]
const _hoisted_16 = { class: "clips" }
const _hoisted_17 = { class: "clips-static" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "clips-bin" }
const _hoisted_20 = { class: "id-label" }
const _hoisted_21 = {
  key: 0,
  class: "source-label"
}
const _hoisted_22 = {
  key: 1,
  class: "voice-label"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("audio", _hoisted_1, null, 512),
    _createElementVNode("audio", _hoisted_2, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, "bamse sounds " + _toDisplayString(_ctx.clientVersion), 1),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playlist, (part, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass({ 'playlist-item': true, active: _ctx.isPlaying && i == _ctx.playIndex }),
            key: part
          }, [
            _createElementVNode("button", {
              disabled: _ctx.isPlaying,
              class: "playlist-button playlist-move-left",
              onClick: ($event: any) => (_ctx.moveAudio(i, -1))
            }, "<", 8, _hoisted_6),
            _createElementVNode("button", {
              disabled: _ctx.isPlaying,
              class: "playlist-button",
              onClick: ($event: any) => (_ctx.removeAudio(i))
            }, _toDisplayString(part), 9, _hoisted_7),
            _createElementVNode("button", {
              disabled: _ctx.isPlaying,
              class: "playlist-button playlist-move-right",
              onClick: ($event: any) => (_ctx.moveAudio(i, 1))
            }, ">", 8, _hoisted_8)
          ], 2))
        }), 128)),
        (_ctx.playlist.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, "Spellistan är tom."))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          class: "button is-play",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.playPlaylist && _ctx.playPlaylist(...args))),
          disabled: _ctx.isPlaying
        }, "Spela", 8, _hoisted_11),
        _createElementVNode("button", {
          class: "button is-stop",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stopPlaylist && _ctx.stopPlaylist(...args))),
          disabled: !_ctx.isPlaying
        }, "Stoppa", 8, _hoisted_12),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearPlaylist && _ctx.clearPlaylist(...args)))
        }, "Rensa"),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.copyLink && _ctx.copyLink(...args)))
        }, "Kopiera länk")
      ]),
      _createElementVNode("div", _hoisted_13, [
        _withDirectives(_createElementVNode("input", {
          class: "input",
          type: "search",
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchTerm) = $event)),
          placeholder: "Sök"
        }, null, 512), [
          [_vModelText, _ctx.searchTerm]
        ]),
        _createElementVNode("button", {
          class: "button",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.searchTerm = ''))
        }, "Rensa")
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packs, (pack) => {
          return (_openBlock(), _createElementBlock("label", {
            key: pack,
            class: _normalizeClass({ active: _ctx.packsEnabled[pack] })
          }, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              "onUpdate:modelValue": ($event: any) => ((_ctx.packsEnabled[pack]) = $event)
            }, null, 8, _hoisted_15), [
              [_vModelCheckbox, _ctx.packsEnabled[pack]]
            ]),
            _createTextVNode(" " + _toDisplayString(pack), 1)
          ], 2))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pauseAmounts, (amount) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "clip",
              key: amount
            }, [
              _createElementVNode("button", {
                class: "button-label noaudio",
                onClick: ($event: any) => (_ctx.addPause(amount))
              }, "Paus " + _toDisplayString(amount) + "ms", 9, _hoisted_18)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredSounds, (sound) => {
            return (_openBlock(), _createElementBlock("li", {
              class: _normalizeClass({ clip: true, active: _ctx.isPreviewing && _ctx.previewFile == sound.path, error: sound.error }),
              key: sound.part + '_' + sound.index
            }, [
              _createElementVNode("div", _hoisted_20, _toDisplayString(sound.part) + "." + _toDisplayString(sound.index), 1),
              (sound.source)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(sound.source), 1))
                : _createCommentVNode("", true),
              (sound.voice)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(sound.voice), 1))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                class: "button-label",
                onClick: ($event: any) => (_ctx.addAudio(sound.part, sound.index))
              }, _toDisplayString(sound.name), 9, _hoisted_23),
              _createElementVNode("button", {
                class: "button-preview",
                onClick: ($event: any) => (_ctx.previewAudio(sound.part, sound.index))
              }, _toDisplayString(_ctx.isPreviewing && _ctx.previewFile == sound.path ? "⏸" : "▶"), 9, _hoisted_24)
            ], 2))
          }), 128))
        ])
      ]),
      (_ctx.isDev)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "button",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.testSounds()))
          }, "Test sounds"))
        : _createCommentVNode("", true)
    ])
  ], 64))
}