interface SoundDbEntry {
    folder?: string;
    file: string;
    source?: string;
    error?: boolean;
    ab?: string;
    voice?: string;
}

export const Packs = ["classic", "b21-trollskogen", "b21-julklappsraset", "b21-sjörövarna"];

export const Sounds = [
    [
        { file: "- elakt skrattande ljud -.ogg" },
        { file: "- jag har lurat bamse- -sången-.ogg" },
        { file: "- jag är bamse starkast utav alla men jag tycker inte om -.ogg" },
        { file: "- usch och fy för bamse - -sången-.ogg" },
        { file: "- vi ska lura bamse - -halva sången-.ogg" },
        { file: "- vi ska lura bamse - -hela sången-.ogg" },
        { file: "- ätande ljud -.ogg" },
        { file: "alla båtar har tjejnamn och det snyggaste tjejnamn jag vet är.ogg" },
        { file: "alla drog och slet i den stackars.ogg" },
        { file: "alla myrorna hurrade för honom.ogg" },
        { file: "alla tyckte det var roligt ända tills det förskräckliga hände.ogg" },
        { file: "alle man ombord.ogg" },
        { file: "allt strunt som ni.ogg" },
        { file: "alltid något.ogg" },
        { file: "använd inte fula ord.ogg" },
        { file: "ba ba bakom dig.ogg" },
        { file: "bamse 2.ogg" },
        { file: "bamse och hans vänner gjorde en liten picnick.ogg" },
        { file: "bamse och hans vänner var hemma och.ogg" },
        { file: "bamse tog på sin blå luva och sprang så fort han kunde upp.ogg" },
        { file: "bamse.ogg" },
        { file: "bil.ogg" },
        { file: "blixtsnabbt hoppade vargen ner i en kista.ogg" },
        { file: "blåste för straffspark.ogg" },
        { file: "blåste rakt in i.ogg" },
        { file: "bättre vara snäll än.ogg" },
        { file: "den håller på att få ett utbrott.ogg" },
        { file: "den ringer när det är dags att äta och sova.ogg" },
        { file: "den ska han inte få behålla länge.ogg" },
        // { file: "det betyder neger.ogg", },
        { file: "det blev tolv stycken.ogg" },
        { file: "det blir man bara elak av.ogg" },
        { file: "det gör väl inget.ogg" },
        { file: "det här har jag väntat på länge.ogg" },
        { file: "det man inte har och inte får det tar man.ogg" },
        { file: "det var bamse.ogg" },
        { file: "det var.ogg" },
        { file: "det är det fulaste ord jag vet.ogg" },
        { file: "det är en vanlig.ogg" },
        // { file: "dom hade det ganska mysigt ända tills.ogg" }, // fix broken audio
        { file: "dom tänker skjuta på oss.ogg" },
        { file: "draken kände lukten av.ogg" },
        { file: "drakens mörka mage.ogg" },
        { file: "du har ingen chans att göra mål på mig.ogg" },
        { file: "du vet att jag sparkar ruskigt hårt.ogg" },
        { file: "du är väl ingen kanon.ogg" },
        { file: "där bor bamse och hans vänner.ogg" },
        { file: "där har han ju det mesta.ogg" },
        { file: "där stod bamse och.ogg" },
        { file: "därifrån hördes snart den här glada sången.ogg" },
        { file: "då fick han syn på något som gjorde honom glad.ogg" },
        { file: "då hördes någonting utanför.ogg" },
        { file: "då kom skalman åkande i sin självgående skottkärra.ogg" },
        { file: "då kom.ogg" },
        { file: "eeh mycket enkelt.ogg" },
        { file: "eeh vad är ni för ena depparejeppar.ogg" },
        { file: "en gång hittade bamse en gammal.ogg" },
        { file: "en vacker syn.ogg" },
        // { file: "familjen grävling.ogg" }, // fix broken audio
        { file: "fann han lille skutt och skalman bundna med rep.ogg" },
        { file: "femtio meter bort.ogg" },
        { file: "flera hundra meter.ogg" },
        { file: "flytta på dig annars kör jag över dig.ogg" },
        { file: "frågade bamse.ogg" },
        { file: "fångade upp honom.ogg" },
        { file: "får akta mig så jag inte trillar ner i hans mage.ogg" },
        { file: "får akta mig så jag inte trillar ner.ogg" },
        { file: "för då är det slut med mig.ogg" },
        { file: "för på lappen stod det.ogg" },
        { file: "förargad linkade vargen därifrån.ogg" },
        // { file: "förstod sig inte heller på kartan utan föreslog att de skulle visa" }, // fix broken audio
        // { file: "den för.ogg" }, // fix broken audio
        { file: "gamla farmor hade just lagat en stor.ogg" },
        { file: "glöm den sa bamse.ogg" },
        { file: "gnällde vargen och sjörövarna.ogg" },
        { file: "HA HA HA.ogg" },
        { file: "hade uppfunnit.ogg" },
        { file: "han hade med sig en lina med krok som han fäste i.ogg" },
        { file: "han hade tagit med sig en stol.ogg" },
        { file: "han låste dörren och hängde för fönstret.ogg" },
        { file: "han spetsade sina stora öron.ogg" },
        { file: "han är nog läbbigt arg.ogg" },
        { file: "han åt tre burkar.ogg" },
        // { file: "hejda honom hejda honom.ogg" }, // fix broken audio
        { file: "hela vinden är full av.ogg" },
        { file: "herr gris 2.ogg" },
        { file: "herr gris.ogg" },
        { file: "hjälp jag kissar på mig.ogg" },
        { file: "hon gick in och stekte köttbullar.ogg" },
        { file: "hurra då kan vi hämta katten.ogg" },
        { file: "hurra.ogg" },
        { file: "här är ju bara vatten.ogg" },
        { file: "hörru vargen.ogg" },
        { file: "i botten av säcken.ogg" },
        { file: "i kistans botten fanns en.ogg" },
        { file: "ingen har nånsin blivit snäll av.ogg" },
        { file: "ingen mer än bamse kan bära så mycket.ogg" },
        { file: "ingen springer så fort som.ogg" },
        { file: "inte ens du.ogg" },
        { file: "inte illa.ogg" },
        { file: "ja 2.ogg" },
        { file: "ja han lurade mig.ogg" },
        { file: "ja jag vet inte.ogg" },
        { file: "ja så klart det är ju jag som har konstruerat den.ogg" },
        { file: "ja.ogg" },
        { file: "jag glömmer aldrig någonting.ogg" },
        { file: "jag gör.ogg" },
        { file: "jag har en inbyggd kompass.ogg" },
        { file: "jag har varnat dig.ogg" },
        { file: "jag kan kartan utantill.ogg" },
        { file: "jag slåss aldrig.ogg" },
        { file: "jag varnade dig.ogg" },
        { file: "jag är en världsfenomenal målvakt.ogg" },
        { file: "jag är jättebäst.ogg" },
        { file: "jaså inget annat.ogg" },
        { file: "jo du sa.ogg" },
        { file: "katten jansson.ogg" },
        { file: "kattkarta.ogg" },
        { file: "klockan tre på eftermiddagen.ogg" },
        { file: "krocka med berget.ogg" },
        { file: "la bollen på plats.ogg" },
        { file: "la krokben.ogg" },
        { file: "lille nallplutt.ogg" },
        { file: "lille skutt.ogg" },
        { file: "mat och sovklocka.ogg" },
        { file: "med allt som behövs för att bygga en båt.ogg" },
        { file: "med en grillad karta blir det ingen kattsökarfärd.ogg" },
        { file: "men 2.ogg" },
        { file: "men missade.ogg" },
        { file: "men uppifrån höga berget hade gamla farmor sett alltihop.ogg" },
        { file: "men.ogg" },
        { file: "metro.ogg" },
        { file: "mickelina räv kom springande och skrek.ogg" },
        { file: "milda makaroner vad det ryker ur farmors.ogg" },
        { file: "nere på havsbotten låg.ogg" },
        { file: "ni vet inte men jag vet.ogg" },
        { file: "nu ska du få betalt för gammal ost.ogg" },
        { file: "nu var det dags att sova frukost.ogg" },
        { file: "nu är du en stollbjörn ingen kan väl hejda ett.ogg" },
        { file: "nä det är sant.ogg" },
        { file: "nä då.ogg" },
        { file: "nä jag tänkte väl det.ogg" },
        { file: "nä om man måste borsta tänderna vill inte jag vara med.ogg" },
        { file: "nä sluta med det där jamandet.ogg" },
        { file: "när bamse tog på sig sin.ogg" },
        { file: "när katten jansson kom springande och jamade.ogg" },
        { file: "någon kommer upp ur den.ogg" },
        { file: "och bamse följde med.ogg" },
        { file: "och började öppna.ogg" },
        { file: "och det är inte lite.ogg" },
        { file: "och dom hörde ett märkligt muller ifrån.ogg" },
        { file: "och dom sprang iväg och satte sig i säkerhet.ogg" },
        { file: "och du har inga kulor heller.ogg" },
        { file: "och en stor marsinpangris.ogg" },
        { file: "och flög ut genom fönstret.ogg" },
        { file: "och gick med arga steg.ogg" },
        { file: "och gick med dystra steg.ogg" },
        { file: "och glufsade den i sig.ogg" },
        { file: "och han frågade farmor vad den föreställde.ogg" },
        { file: "och hejdade sig.ogg" },
        { file: "och hennes lillebror.ogg" },
        { file: "och höll emot.ogg" },
        { file: "och kastade tillbaka dom.ogg" },
        { file: "och körde på.ogg" },
        { file: "och lyfte upp.ogg" },
        // { file: "och medan julsnön började att falla.ogg" }, // fix broken audio
        { file: "och mickelina skrek förtvivlad.ogg" },
        { file: "och när farmor sträckte fram.ogg" },
        { file: "och plötsligt stod någon mitt i vägen.ogg" },
        // { file: "och sen åt dom allihop.ogg" }, // fix broken audio
        { file: "och skråla glatt.ogg" },
        { file: "och sparkade till.ogg" },
        { file: "och stängde locket.ogg" },
        { file: "och tog upp en ihopfällbar.ogg" },
        { file: "och var på extra dåligt humör.ogg" },
        { file: "och vargen försvann.ogg" },
        { file: "passar bra.ogg" },
        { file: "på bergets sluttning.ogg" },
        { file: "på en sten satt lille skutt och bölade.ogg" },
        { file: "på farmors vind.ogg" },
        { file: "på golvet.ogg" },
        { file: "på havets botten.ogg" },
        { file: "på höga berget.ogg" },
        { file: "på små kullar ligger några lustiga.ogg" },
        { file: "på vägen hem.ogg" },
        { file: "rakt in i elden och brann upp.ogg" },
        { file: "ropade han till sina vänner.ogg" },
        { file: "ropade lille skutt.ogg" },
        { file: "rullade stora klippblock ner över.ogg" },
        { file: "sa Bamse.ogg" },
        { file: "sa farmor.ogg" },
        { file: "sa han.ogg" },
        { file: "sa Kapten Buster bistert.ogg" },
        { file: "sa Kapten Buster.ogg" },
        { file: "sa Lille Skutt.ogg" },
        { file: "sa piggelkott.ogg" },
        { file: "sa Rusken.ogg" },
        { file: "sa Skalman.ogg" },
        { file: "sa Slusken.ogg" },
        { file: "sa Stollen.ogg" },
        { file: "sa Vargen.ogg" },
        { file: "saft och kakor och bullar.ogg" },
        { file: "satte gaspedalen i botten.ogg" },
        { file: "sjörövarna skjöt tre skott.ogg" },
        { file: "skalman 2.ogg" },
        { file: "skalman.ogg" },
        { file: "skattkarta.ogg" },
        { file: "skrek han.ogg" },
        { file: "skrek vargen 2.ogg" },
        // { file: "skridskor skidor.ogg" }, // fix broken audio
        { file: "skyll dig själv.ogg" },
        { file: "skärp dig.ogg" },
        { file: "snabbt.ogg" },
        { file: "snart ska både kartan och katten vara mina.ogg" },
        { file: "snickeriding struntsnack.ogg" },
        { file: "som drevs med lingonsaft.ogg" },
        { file: "som han länge hade velat ha.ogg" },
        { file: "som såg ut som ett ihoptryckt dragspel.ogg" },
        { file: "sparka.ogg" },
        { file: "stammade han.ogg" },
        // { file: "sträckte fram huvudet och skjöt ut sin långa tunga och slickade i" }, // fix broken audio
        // { file: "sig.ogg" }, // fix broken audio
        // { file: "så hoppade bamse ut.ogg" }, // fix broken audio
        { file: "sånt här skräp vill vi inte ha ombord sa han.ogg", voice: "bamse" },
        { file: "till vargen.ogg" },
        { file: "till vargens skog.ogg" },
        { file: "titta.ogg" },
        { file: "tog sats.ogg" },
        { file: "trevåningshus.ogg" },
        { file: "TVI.ogg" },
        { file: "tänkte bamse 2.ogg" },
        { file: "tänkte bamse.ogg" },
        { file: "tänkte han 2.ogg" },
        { file: "tänkte han gömma sig där.ogg" },
        { file: "tänkte han.ogg" },
        { file: "tänkte katten jansson.ogg" },
        // { file: "ur skalet.ogg" }, // fix broken audio
        { file: "usch.ogg" },
        { file: "utan karta vet vi inte var katten finns.ogg" },
        { file: "va sa du förresten.ogg" },
        { file: "va va var finns skatten.ogg" },
        { file: "var är.ogg" },
        { file: "vargen 2.ogg" },
        { file: "vargen satt hemma.ogg" },
        { file: "vargen smög fram bakom några.ogg" },
        { file: "vargen.ogg" },
        { file: "vargkusinerna.ogg" },
        { file: "vart tog han vägen.ogg" },
        { file: "vi måste göra något gör något gör något.ogg" },
        { file: "vi sätter igång med det samma.ogg" },
        { file: "vi vi vi vann.ogg" },
        // { file: "vulkangrillad korv.ogg" }, // fix broken audio
        { file: "ynkryggar.ogg" },
        { file: "är man världens starkaste så är man.ogg" },
        { file: "ääöä.ogg" },
    ],
    [
        { file: "-elakt skrattljud-.ogg" },
        { file: "-piskljud-.ogg" },
        { file: "-ringljud- hela.ogg" },
        { file: "-ringljud- slutet.ogg" },
        { file: "-skjuten person-.ogg" },
        { file: "-skrikljud-.ogg" },
        { file: "-skrikljud2-.ogg" },
        { file: "bamse 2.ogg" },
        { file: "bamse smackade.ogg" },
        { file: "bamse ställde sig mitt i vägen.ogg" },
        { file: "bamse.ogg" },
        { file: "bet av.ogg" },
        { file: "blev dom mycket sorgsna.ogg" },
        { file: "dags för start igen.ogg" },
        { file: "den lilla myran.ogg" },
        { file: "den ringer när det är dags att äta eller att sova.ogg" },
        { file: "det blir ingen nästa gång.ogg" },
        { file: "det går kanske bättre nästa gång.ogg" },
        { file: "det här var ju hopplöst.ogg" },
        { file: "det knarrade förskräckligt.ogg" },
        { file: "det lät som brandlarm.ogg" },
        { file: "det låter jag dig inte göra.ogg" },
        { file: "det var en visselpipa.ogg" },
        { file: "det är han alltid.ogg" },
        { file: "det är ju det jag gör.ogg" },
        { file: "dom gick i en lång rad och tittade på.ogg" },
        { file: "dom vågade knappast andas.ogg" },
        { file: "du blir så lagom mallig när du känner piskan.ogg" },
        { file: "där satte den sig.ogg" },
        { file: "då kom.ogg" },
        { file: "då skrattade alla myrorna.ogg" },
        // { file: "då skrattade hela publiken.ogg" }, // fix broken audio
        { file: "då virvlade någonting förbi.ogg" },
        // { file: "då.ogg" }, // fix broken audio
        { file: "En burk dunderhonung.ogg" },
        { file: "ett rapp med piskan.ogg" },
        { file: "farmor hade gjort en liten sulky till katten jansson.ogg" },
        { file: "farmors.ogg" },
        { file: "femtion kronor för det usla kräket.ogg" },
        { file: "flinade sorkarna bakom dom.ogg" },
        { file: "fräste katten.ogg" },
        { file: "frågade skalman.ogg" },
        { file: "fånfånigt.ogg" },
        { file: "förargad.ogg" },
        { file: "gick fram till bamse och sa.ogg" },
        { file: "gjorde en sulky.ogg" },
        { file: "glöm det.ogg" },
        { file: "han sparkade till.ogg" },
        { file: "han tar någonting ur skalet.ogg" },
        { file: "han tog i allt vad han kunde.ogg" },
        { file: "han är inte så bra på det.ogg" },
        { file: "hojtade husmusen.ogg" },
        { file: "husmusen höll en spik i svansen.ogg" },
        { file: "husmusen.ogg" },
        { file: "här kan du inte sitta du blir överkörd.ogg" },
        { file: "hånade sorkarna.ogg" },
        { file: "ibland gav han.ogg" },
        { file: "ingen konst.ogg" },
        { file: "inte skalman.ogg" },
        { file: "ja dåså.ogg" },
        { file: "ja.ogg", voice: "lilleskutt" },
        { file: "jag köper den för femtio kronor.ogg" },
        { file: "jag är riktigt arg.ogg" },
        { file: "jag är riktigt avundsjuk.ogg" },
        { file: "kan.ogg" },
        { file: "katten höll svansen alledes stilla.ogg" },
        { file: "katten jansson och husmusen hjälpte också till.ogg" },
        { file: "kattens svans.ogg" },
        { file: "kikade bamse fram mellan fingrarna.ogg" },
        { file: "knappast.ogg" },
        { file: "kom så får du socker.ogg" },
        { file: "kunde göra sitt morotsland dubbelt så stort.ogg" },
        // { file: "larva er inte.ogg" }, // fix broken audio
        { file: "lille skutt körde.ogg" },
        { file: "Lille Skutt.ogg" },
        { file: "låda.ogg" },
        { file: "Låg i vägen.ogg" },
        { file: "låg visselpipan.ogg" },
        { file: "med arga och bestämda steg.ogg" },
        // { file: "med ett enda kraftigt tag sågade han itu.ogg" }, // fix broken audio
        { file: "med ett finger.ogg" },
        { file: "medan de skruvade spikade och sågade.ogg" },
        { file: "men av det fick han bara ont i foten.ogg" },
        { file: "men det hjälpte inte.ogg" },
        { file: "Men en stor sten.ogg" },
        // { file: "muttrade lille skutt.ogg" }, // fix broken audio
        { file: "nu går jag hem.ogg" },
        { file: "nu ska ni få se på racertakter.ogg" },
        { file: "nä.ogg" },
        { file: "nästa lördag.ogg" },
        { file: "Nästan i alla fall.ogg" },
        { file: "och band den.ogg" },
        { file: "och dom turades om att köra.ogg" },
        { file: "och hoppade av förtjusning.ogg" },
        { file: "och inte nog med det.ogg" },
        { file: "och klafsade iväg ett rapp mot.ogg" },
        { file: "och knäppte till.ogg" },
        { file: "och log finurligt med halva munnen.ogg" },
        { file: "och myrdrottningen sa.ogg" },
        { file: "och sa.ogg" },
        { file: "och somnade.ogg" },
        { file: "och tog med sig åsnan.ogg" },
        { file: "om.ogg" },
        { file: "orättvist att dom har en åsna och inte jag.ogg" },
        { file: "ovanpå säckarna.ogg" },
        { file: "pinne.ogg" },
        { file: "plötsligt spände lille skutt upp sina stora öron.ogg" },
        { file: "riktigt arg.ogg" },
        { file: "ropade mickelina.ogg" },
        { file: "rullade långt iväg.ogg" },
        { file: "rädd för vad han skulle få se.ogg" },
        { file: "sa en röst bakom trädet.ogg" },
        { file: "sa fixarfrasse.ogg" },
        { file: "sa husmusen plötsligt.ogg" },
        { file: "sa katten jansson.ogg" },
        { file: "sa katten.ogg" },
        { file: "sa mannen.ogg" },
        { file: "sa mickelina.ogg" },
        { file: "sa ola grävling 2.ogg" },
        { file: "sa ola grävling.ogg" },
        { file: "satt en stor kraftig man.ogg" },
        { file: "satte en gren under och försökte välta bort.ogg" },
        { file: "skalman blåste och åsnan sprang.ogg" },
        { file: "skalman log med andra halvan av munnen.ogg" },
        { file: "skalman såg alltsammans i sin kikare.ogg" },
        { file: "skalman.ogg" },
        { file: "skrek vargen.ogg" },
        { file: "slet och kämpade för att få hem.ogg" },
        { file: "slog till med hammaren.ogg" },
        { file: "små vänner är också bra vänner.ogg" },
        { file: "snart fick han se något som gjorde honom arg.ogg" },
        { file: "som han hade hittat på själv.ogg" },
        // { file: "som lille skutt hade lagt dit.ogg" }, // fix broken audio
        { file: "som ola grävling hade hittat på.ogg" },
        { file: "som skalman började blåsa i.ogg" },
        { file: "stark är han bara när han har ätit.ogg" },
        { file: "stopp.ogg" },
        { file: "suckade mickelina.ogg" },
        { file: "så får du aldrig göra mer.ogg" },
        { file: "så gör jag aldrig.ogg" },
        { file: "till sorkarna.ogg" },
        // { file: "Tänkte göra sitt morotsland lite större.ogg" }, // fix broken audio
        { file: "uppe på taket.ogg" },
        { file: "vad har den långörade pajasen här och göra.ogg" },
        { file: "varför gör du så där.ogg" },
        { file: "Varje morgon.ogg" },
        { file: "vi anmäler honom till en tävling.ogg" },
        { file: "Världsmästare i elakhet.ogg" },
        { file: "Äter.ogg" },
        { file: "åsnan stod ett stycke bort från bygget och mumsade på.ogg" },
    ],
    [
        { file: "(gyttja).ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "(pil).ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "(punch1).ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "(punch2).ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "(yeow).ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "arbetade i sitt morotsland.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "bakom.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "bamse - följ med så får du också.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "bamse" },
        { file: "bamse - pannkakor.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "bamse" },
        { file: "bamse - res dig upp.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "bamse" },
        { file: "bamse - vi behöver alla en ny omgång av farmors.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "bamse" },
        { file: "bamse kliade sig i nacken.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "bamse kom.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "bamse stod bredbent.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "bamses blå.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "brände det till i svansen.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "böjde sig ner.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "den här gången gick det inte lika lätt.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "deras sängar skakade.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "det började en morgon när.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "det var en sak som lille skutt inte visste.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "det var inte svårt att ta.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "dom rusade rakt på honom.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "du måste.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "dunderhonung.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "dunderladdad.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "där bor lille skutt.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "där kom.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "där traffade han.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "då hördes ett otäckt skratt.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "då ringde skalmans mat och sovklocka.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "en tjock.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "en.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "flög iväg mot bamse.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "fnyste katten.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "fy.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "för dom låg på hia-hias.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "försiktigt kikade dom fram ur buskarna.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "först kom det bara rök.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han gnuggade ögonen med tassen.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han gnuggade.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han hade bundit fast.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han klämde i allt vad han orkade.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han rusade in i stubben och såg i spegeln.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "han tog av sin.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "hon nosade sig rakt fram mot.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "i en hög.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "jag tror jag vet vad som finns i andra ändan.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "katten jansson och husmusen tittade på.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "katten tyckte inte det var roligt.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "kista.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "kom.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "lille skutt kände att något hände med hans öron.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "lille skutt kände på sina.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "luktade sig till den.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "lång trång gång.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "men då.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "men inte nog med det.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "men vilket elände.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "mycket tätt intill varandra.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "mör och fin.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "nu är det dags att sova frukost.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "näe.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "när 2.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "när.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "några såg ut som svampar.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och dom höll sig tätt.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och hon.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och klämde försiktigt på.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och nosade.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och sträckte ut en arm mot.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och så låg dom där.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "och visst var det en burk.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "ola grävling.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "pekade på en stor.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "plåtburk.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "plötsligt märkte han.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "på magen.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "rann nerför hans kind.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "rullade han iväg den.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sa bamse och klappade sin lilla vän på axeln.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sa husmusen.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sedan några röda gnistor.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sjunka ner i det.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "skalman - det är gott.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "skalman" },
        { file: "skalman - surströmming.ogg", source: "b21-trollskogen", ab: "trollskogen", voice: "skalman" },
        { file: "skalman hade tagit av sig.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "skalman satte på sig.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "smaka, jansson.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sniff sniff.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "som tog honom i morötterna.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "spruta.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "sprutade.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "staven.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "stubbe.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "så att den hamnade bakom henne.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "titta lite närmare.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "tog spjärn med ena foten.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "trollstaven.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "tränga sig.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "ur skalet.ogg", source: "b21-trollskogen", ab: "trollskogen" },
        { file: "usch.ogg", source: "b21-trollskogen", ab: "trollskogen" },
    ],
    [
        { file: "(fjäder).ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "(skratt).ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "(slidewhistle).ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "alla börjar gräva.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "alla kompisarna i en lång rad.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "avsvimmad och hjälplös.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "bamse blir förskräckt.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "bamse gräver snabbt.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "bamse har med sig en hel säck full med.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "bamse är avsvimmad.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "bit för bit.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "dom tar.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "dom öppnar dörren.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "drar ner slangen.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "då knackade det på dörren.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "då kommer.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "efter en stund.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "en dag strax före jul.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "en liten smal.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "gapa.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "han släpper.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "han sätter igång.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "hoppas att ingen kom i vägen.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "julklappar.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "liten smal.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "när dom plötsligt.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "och börjar undersöka.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "och dom kan inte få honom att gapa.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "och räcker fram den stora.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "och springer med utsträckta armar.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "och äter en burk dunderhonung.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "sen sätter han.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "skalman - sätt den här i bamses mun.ogg", source: "b21-julklappsraset", ab: "julklappsraset", voice: "skalman" },
        { file: "skalman - så häller jag.ogg", source: "b21-julklappsraset", ab: "julklappsraset", voice: "skalman" },
        { file: "skalman - tre meter ner.ogg", source: "b21-julklappsraset", ab: "julklappsraset", voice: "skalman" },
        { file: "skalman plockar fram.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "som ligger livlösa i en hög.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "tomten är avsvimmad.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "tunnel.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "vargen hinner precis fram.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "_bamse och julklappsraset", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "är för hård.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
        { file: "över nosen på bamse.ogg", source: "b21-julklappsraset", ab: "julklappsraset" },
    ],
    [
        { folder: "sjörövarna", file: "bamse tog på en.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "då ryckte han i.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "men.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "när bamse kom.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "och la ut.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "röv.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
        { folder: "sjörövarna", file: "så laddade han upp sig.ogg", source: "b21-sjörövarna", ab: "sjörövarna" },
    ],
] as SoundDbEntry[][];
